import Projects from "./Projects";

// const filtersArray = [
//   "All",
//   "Python",
//   "C++",
//   "JavaScript",
//   "Golang",
//   "React",
//   "React Native",
//   "Three",
//   "Node",
//   "Express",
//   "MySQL",
//   "PostgreSQL",
//   "MongoDB",
//   "Heroku",
//   "Firebase",
//   "Docker",
// ];

const allSkills = Projects.reduce(
  (all, current) => all.concat(current.skillsArray),
  []
);
const allSkillsSet = new Set(allSkills);
allSkillsSet.delete("All");
const filtersArray = [...allSkillsSet];
filtersArray.unshift("All");

export default filtersArray;
